var githubDarkDefault = Object.freeze({
  "colors": {
    "activityBar.activeBorder": "#f78166",
    "activityBar.background": "#0d1117",
    "activityBar.border": "#30363d",
    "activityBar.foreground": "#e6edf3",
    "activityBar.inactiveForeground": "#7d8590",
    "activityBarBadge.background": "#1f6feb",
    "activityBarBadge.foreground": "#ffffff",
    "badge.background": "#1f6feb",
    "badge.foreground": "#ffffff",
    "breadcrumb.activeSelectionForeground": "#7d8590",
    "breadcrumb.focusForeground": "#e6edf3",
    "breadcrumb.foreground": "#7d8590",
    "breadcrumbPicker.background": "#161b22",
    "button.background": "#238636",
    "button.foreground": "#ffffff",
    "button.hoverBackground": "#2ea043",
    "button.secondaryBackground": "#282e33",
    "button.secondaryForeground": "#c9d1d9",
    "button.secondaryHoverBackground": "#30363d",
    "checkbox.background": "#161b22",
    "checkbox.border": "#30363d",
    "debugConsole.errorForeground": "#ffa198",
    "debugConsole.infoForeground": "#8b949e",
    "debugConsole.sourceForeground": "#e3b341",
    "debugConsole.warningForeground": "#d29922",
    "debugConsoleInputIcon.foreground": "#bc8cff",
    "debugIcon.breakpointForeground": "#f85149",
    "debugTokenExpression.boolean": "#56d364",
    "debugTokenExpression.error": "#ffa198",
    "debugTokenExpression.name": "#79c0ff",
    "debugTokenExpression.number": "#56d364",
    "debugTokenExpression.string": "#a5d6ff",
    "debugTokenExpression.value": "#a5d6ff",
    "debugToolBar.background": "#161b22",
    "descriptionForeground": "#7d8590",
    "diffEditor.insertedLineBackground": "#23863626",
    "diffEditor.insertedTextBackground": "#3fb9504d",
    "diffEditor.removedLineBackground": "#da363326",
    "diffEditor.removedTextBackground": "#ff7b724d",
    "dropdown.background": "#161b22",
    "dropdown.border": "#30363d",
    "dropdown.foreground": "#e6edf3",
    "dropdown.listBackground": "#161b22",
    "editor.background": "#0d1117",
    "editor.findMatchBackground": "#9e6a03",
    "editor.findMatchHighlightBackground": "#f2cc6080",
    "editor.focusedStackFrameHighlightBackground": "#2ea04366",
    "editor.foldBackground": "#6e76811a",
    "editor.foreground": "#e6edf3",
    "editor.lineHighlightBackground": "#6e76811a",
    "editor.linkedEditingBackground": "#2f81f712",
    "editor.selectionHighlightBackground": "#3fb95040",
    "editor.stackFrameHighlightBackground": "#bb800966",
    "editor.wordHighlightBackground": "#6e768180",
    "editor.wordHighlightBorder": "#6e768199",
    "editor.wordHighlightStrongBackground": "#6e76814d",
    "editor.wordHighlightStrongBorder": "#6e768199",
    "editorBracketHighlight.foreground1": "#79c0ff",
    "editorBracketHighlight.foreground2": "#56d364",
    "editorBracketHighlight.foreground3": "#e3b341",
    "editorBracketHighlight.foreground4": "#ffa198",
    "editorBracketHighlight.foreground5": "#ff9bce",
    "editorBracketHighlight.foreground6": "#d2a8ff",
    "editorBracketHighlight.unexpectedBracket.foreground": "#7d8590",
    "editorBracketMatch.background": "#3fb95040",
    "editorBracketMatch.border": "#3fb95099",
    "editorCursor.foreground": "#2f81f7",
    "editorGroup.border": "#30363d",
    "editorGroupHeader.tabsBackground": "#010409",
    "editorGroupHeader.tabsBorder": "#30363d",
    "editorGutter.addedBackground": "#2ea04366",
    "editorGutter.deletedBackground": "#f8514966",
    "editorGutter.modifiedBackground": "#bb800966",
    "editorIndentGuide.activeBackground": "#e6edf33d",
    "editorIndentGuide.background": "#e6edf31f",
    "editorInlayHint.background": "#8b949e33",
    "editorInlayHint.foreground": "#7d8590",
    "editorInlayHint.paramBackground": "#8b949e33",
    "editorInlayHint.paramForeground": "#7d8590",
    "editorInlayHint.typeBackground": "#8b949e33",
    "editorInlayHint.typeForeground": "#7d8590",
    "editorLineNumber.activeForeground": "#e6edf3",
    "editorLineNumber.foreground": "#6e7681",
    "editorOverviewRuler.border": "#010409",
    "editorWhitespace.foreground": "#484f58",
    "editorWidget.background": "#161b22",
    "errorForeground": "#f85149",
    "focusBorder": "#1f6feb",
    "foreground": "#e6edf3",
    "gitDecoration.addedResourceForeground": "#3fb950",
    "gitDecoration.conflictingResourceForeground": "#db6d28",
    "gitDecoration.deletedResourceForeground": "#f85149",
    "gitDecoration.ignoredResourceForeground": "#6e7681",
    "gitDecoration.modifiedResourceForeground": "#d29922",
    "gitDecoration.submoduleResourceForeground": "#7d8590",
    "gitDecoration.untrackedResourceForeground": "#3fb950",
    "icon.foreground": "#7d8590",
    "input.background": "#0d1117",
    "input.border": "#30363d",
    "input.foreground": "#e6edf3",
    "input.placeholderForeground": "#6e7681",
    "keybindingLabel.foreground": "#e6edf3",
    "list.activeSelectionBackground": "#6e768166",
    "list.activeSelectionForeground": "#e6edf3",
    "list.focusBackground": "#388bfd26",
    "list.focusForeground": "#e6edf3",
    "list.highlightForeground": "#2f81f7",
    "list.hoverBackground": "#6e76811a",
    "list.hoverForeground": "#e6edf3",
    "list.inactiveFocusBackground": "#388bfd26",
    "list.inactiveSelectionBackground": "#6e768166",
    "list.inactiveSelectionForeground": "#e6edf3",
    "minimapSlider.activeBackground": "#8b949e47",
    "minimapSlider.background": "#8b949e33",
    "minimapSlider.hoverBackground": "#8b949e3d",
    "notificationCenterHeader.background": "#161b22",
    "notificationCenterHeader.foreground": "#7d8590",
    "notifications.background": "#161b22",
    "notifications.border": "#30363d",
    "notifications.foreground": "#e6edf3",
    "notificationsErrorIcon.foreground": "#f85149",
    "notificationsInfoIcon.foreground": "#2f81f7",
    "notificationsWarningIcon.foreground": "#d29922",
    "panel.background": "#010409",
    "panel.border": "#30363d",
    "panelInput.border": "#30363d",
    "panelTitle.activeBorder": "#f78166",
    "panelTitle.activeForeground": "#e6edf3",
    "panelTitle.inactiveForeground": "#7d8590",
    "peekViewEditor.background": "#6e76811a",
    "peekViewEditor.matchHighlightBackground": "#bb800966",
    "peekViewResult.background": "#0d1117",
    "peekViewResult.matchHighlightBackground": "#bb800966",
    "pickerGroup.border": "#30363d",
    "pickerGroup.foreground": "#7d8590",
    "progressBar.background": "#1f6feb",
    "quickInput.background": "#161b22",
    "quickInput.foreground": "#e6edf3",
    "scrollbar.shadow": "#484f5833",
    "scrollbarSlider.activeBackground": "#8b949e47",
    "scrollbarSlider.background": "#8b949e33",
    "scrollbarSlider.hoverBackground": "#8b949e3d",
    "settings.headerForeground": "#e6edf3",
    "settings.modifiedItemIndicator": "#bb800966",
    "sideBar.background": "#010409",
    "sideBar.border": "#30363d",
    "sideBar.foreground": "#e6edf3",
    "sideBarSectionHeader.background": "#010409",
    "sideBarSectionHeader.border": "#30363d",
    "sideBarSectionHeader.foreground": "#e6edf3",
    "sideBarTitle.foreground": "#e6edf3",
    "statusBar.background": "#0d1117",
    "statusBar.border": "#30363d",
    "statusBar.debuggingBackground": "#da3633",
    "statusBar.debuggingForeground": "#ffffff",
    "statusBar.focusBorder": "#1f6feb80",
    "statusBar.foreground": "#7d8590",
    "statusBar.noFolderBackground": "#0d1117",
    "statusBarItem.activeBackground": "#e6edf31f",
    "statusBarItem.focusBorder": "#1f6feb",
    "statusBarItem.hoverBackground": "#e6edf314",
    "statusBarItem.prominentBackground": "#6e768166",
    "statusBarItem.remoteBackground": "#30363d",
    "statusBarItem.remoteForeground": "#e6edf3",
    "symbolIcon.arrayForeground": "#f0883e",
    "symbolIcon.booleanForeground": "#58a6ff",
    "symbolIcon.classForeground": "#f0883e",
    "symbolIcon.colorForeground": "#79c0ff",
    "symbolIcon.constantForeground": [
      "#aff5b4",
      "#7ee787",
      "#56d364",
      "#3fb950",
      "#2ea043",
      "#238636",
      "#196c2e",
      "#0f5323",
      "#033a16",
      "#04260f"
    ],
    "symbolIcon.constructorForeground": "#d2a8ff",
    "symbolIcon.enumeratorForeground": "#f0883e",
    "symbolIcon.enumeratorMemberForeground": "#58a6ff",
    "symbolIcon.eventForeground": "#6e7681",
    "symbolIcon.fieldForeground": "#f0883e",
    "symbolIcon.fileForeground": "#d29922",
    "symbolIcon.folderForeground": "#d29922",
    "symbolIcon.functionForeground": "#bc8cff",
    "symbolIcon.interfaceForeground": "#f0883e",
    "symbolIcon.keyForeground": "#58a6ff",
    "symbolIcon.keywordForeground": "#ff7b72",
    "symbolIcon.methodForeground": "#bc8cff",
    "symbolIcon.moduleForeground": "#ff7b72",
    "symbolIcon.namespaceForeground": "#ff7b72",
    "symbolIcon.nullForeground": "#58a6ff",
    "symbolIcon.numberForeground": "#3fb950",
    "symbolIcon.objectForeground": "#f0883e",
    "symbolIcon.operatorForeground": "#79c0ff",
    "symbolIcon.packageForeground": "#f0883e",
    "symbolIcon.propertyForeground": "#f0883e",
    "symbolIcon.referenceForeground": "#58a6ff",
    "symbolIcon.snippetForeground": "#58a6ff",
    "symbolIcon.stringForeground": "#79c0ff",
    "symbolIcon.structForeground": "#f0883e",
    "symbolIcon.textForeground": "#79c0ff",
    "symbolIcon.typeParameterForeground": "#79c0ff",
    "symbolIcon.unitForeground": "#58a6ff",
    "symbolIcon.variableForeground": "#f0883e",
    "tab.activeBackground": "#0d1117",
    "tab.activeBorder": "#0d1117",
    "tab.activeBorderTop": "#f78166",
    "tab.activeForeground": "#e6edf3",
    "tab.border": "#30363d",
    "tab.hoverBackground": "#0d1117",
    "tab.inactiveBackground": "#010409",
    "tab.inactiveForeground": "#7d8590",
    "tab.unfocusedActiveBorder": "#0d1117",
    "tab.unfocusedActiveBorderTop": "#30363d",
    "tab.unfocusedHoverBackground": "#6e76811a",
    "terminal.ansiBlack": "#484f58",
    "terminal.ansiBlue": "#58a6ff",
    "terminal.ansiBrightBlack": "#6e7681",
    "terminal.ansiBrightBlue": "#79c0ff",
    "terminal.ansiBrightCyan": "#56d4dd",
    "terminal.ansiBrightGreen": "#56d364",
    "terminal.ansiBrightMagenta": "#d2a8ff",
    "terminal.ansiBrightRed": "#ffa198",
    "terminal.ansiBrightWhite": "#ffffff",
    "terminal.ansiBrightYellow": "#e3b341",
    "terminal.ansiCyan": "#39c5cf",
    "terminal.ansiGreen": "#3fb950",
    "terminal.ansiMagenta": "#bc8cff",
    "terminal.ansiRed": "#ff7b72",
    "terminal.ansiWhite": "#b1bac4",
    "terminal.ansiYellow": "#d29922",
    "terminal.foreground": "#e6edf3",
    "textBlockQuote.background": "#010409",
    "textBlockQuote.border": "#30363d",
    "textCodeBlock.background": "#6e768166",
    "textLink.activeForeground": "#2f81f7",
    "textLink.foreground": "#2f81f7",
    "textPreformat.foreground": "#7d8590",
    "textSeparator.foreground": "#21262d",
    "titleBar.activeBackground": "#0d1117",
    "titleBar.activeForeground": "#7d8590",
    "titleBar.border": "#30363d",
    "titleBar.inactiveBackground": "#010409",
    "titleBar.inactiveForeground": "#7d8590",
    "tree.indentGuidesStroke": "#21262d",
    "welcomePage.buttonBackground": "#21262d",
    "welcomePage.buttonHoverBackground": "#30363d"
  },
  "displayName": "GitHub Dark Default",
  "name": "github-dark-default",
  "semanticHighlighting": true,
  "tokenColors": [
    {
      "scope": [
        "comment",
        "punctuation.definition.comment",
        "string.comment"
      ],
      "settings": {
        "foreground": "#8b949e"
      }
    },
    {
      "scope": [
        "constant.other.placeholder",
        "constant.character"
      ],
      "settings": {
        "foreground": "#ff7b72"
      }
    },
    {
      "scope": [
        "constant",
        "entity.name.constant",
        "variable.other.constant",
        "variable.other.enummember",
        "variable.language",
        "entity"
      ],
      "settings": {
        "foreground": "#79c0ff"
      }
    },
    {
      "scope": [
        "entity.name",
        "meta.export.default",
        "meta.definition.variable"
      ],
      "settings": {
        "foreground": "#ffa657"
      }
    },
    {
      "scope": [
        "variable.parameter.function",
        "meta.jsx.children",
        "meta.block",
        "meta.tag.attributes",
        "entity.name.constant",
        "meta.object.member",
        "meta.embedded.expression"
      ],
      "settings": {
        "foreground": "#e6edf3"
      }
    },
    {
      "scope": "entity.name.function",
      "settings": {
        "foreground": "#d2a8ff"
      }
    },
    {
      "scope": [
        "entity.name.tag",
        "support.class.component"
      ],
      "settings": {
        "foreground": "#7ee787"
      }
    },
    {
      "scope": "keyword",
      "settings": {
        "foreground": "#ff7b72"
      }
    },
    {
      "scope": [
        "storage",
        "storage.type"
      ],
      "settings": {
        "foreground": "#ff7b72"
      }
    },
    {
      "scope": [
        "storage.modifier.package",
        "storage.modifier.import",
        "storage.type.java"
      ],
      "settings": {
        "foreground": "#e6edf3"
      }
    },
    {
      "scope": [
        "string",
        "string punctuation.section.embedded source"
      ],
      "settings": {
        "foreground": "#a5d6ff"
      }
    },
    {
      "scope": "support",
      "settings": {
        "foreground": "#79c0ff"
      }
    },
    {
      "scope": "meta.property-name",
      "settings": {
        "foreground": "#79c0ff"
      }
    },
    {
      "scope": "variable",
      "settings": {
        "foreground": "#ffa657"
      }
    },
    {
      "scope": "variable.other",
      "settings": {
        "foreground": "#e6edf3"
      }
    },
    {
      "scope": "invalid.broken",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ffa198"
      }
    },
    {
      "scope": "invalid.deprecated",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ffa198"
      }
    },
    {
      "scope": "invalid.illegal",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ffa198"
      }
    },
    {
      "scope": "invalid.unimplemented",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ffa198"
      }
    },
    {
      "scope": "carriage-return",
      "settings": {
        "background": "#ff7b72",
        "content": "^M",
        "fontStyle": "italic underline",
        "foreground": "#f0f6fc"
      }
    },
    {
      "scope": "message.error",
      "settings": {
        "foreground": "#ffa198"
      }
    },
    {
      "scope": "string variable",
      "settings": {
        "foreground": "#79c0ff"
      }
    },
    {
      "scope": [
        "source.regexp",
        "string.regexp"
      ],
      "settings": {
        "foreground": "#a5d6ff"
      }
    },
    {
      "scope": [
        "string.regexp.character-class",
        "string.regexp constant.character.escape",
        "string.regexp source.ruby.embedded",
        "string.regexp string.regexp.arbitrary-repitition"
      ],
      "settings": {
        "foreground": "#a5d6ff"
      }
    },
    {
      "scope": "string.regexp constant.character.escape",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#7ee787"
      }
    },
    {
      "scope": "support.constant",
      "settings": {
        "foreground": "#79c0ff"
      }
    },
    {
      "scope": "support.variable",
      "settings": {
        "foreground": "#79c0ff"
      }
    },
    {
      "scope": "support.type.property-name.json",
      "settings": {
        "foreground": "#7ee787"
      }
    },
    {
      "scope": "meta.module-reference",
      "settings": {
        "foreground": "#79c0ff"
      }
    },
    {
      "scope": "punctuation.definition.list.begin.markdown",
      "settings": {
        "foreground": "#ffa657"
      }
    },
    {
      "scope": [
        "markup.heading",
        "markup.heading entity.name"
      ],
      "settings": {
        "fontStyle": "bold",
        "foreground": "#79c0ff"
      }
    },
    {
      "scope": "markup.quote",
      "settings": {
        "foreground": "#7ee787"
      }
    },
    {
      "scope": "markup.italic",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#e6edf3"
      }
    },
    {
      "scope": "markup.bold",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#e6edf3"
      }
    },
    {
      "scope": [
        "markup.underline"
      ],
      "settings": {
        "fontStyle": "underline"
      }
    },
    {
      "scope": [
        "markup.strikethrough"
      ],
      "settings": {
        "fontStyle": "strikethrough"
      }
    },
    {
      "scope": "markup.inline.raw",
      "settings": {
        "foreground": "#79c0ff"
      }
    },
    {
      "scope": [
        "markup.deleted",
        "meta.diff.header.from-file",
        "punctuation.definition.deleted"
      ],
      "settings": {
        "background": "#490202",
        "foreground": "#ffa198"
      }
    },
    {
      "scope": [
        "punctuation.section.embedded"
      ],
      "settings": {
        "foreground": "#ff7b72"
      }
    },
    {
      "scope": [
        "markup.inserted",
        "meta.diff.header.to-file",
        "punctuation.definition.inserted"
      ],
      "settings": {
        "background": "#04260f",
        "foreground": "#7ee787"
      }
    },
    {
      "scope": [
        "markup.changed",
        "punctuation.definition.changed"
      ],
      "settings": {
        "background": "#5a1e02",
        "foreground": "#ffa657"
      }
    },
    {
      "scope": [
        "markup.ignored",
        "markup.untracked"
      ],
      "settings": {
        "background": "#79c0ff",
        "foreground": "#161b22"
      }
    },
    {
      "scope": "meta.diff.range",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#d2a8ff"
      }
    },
    {
      "scope": "meta.diff.header",
      "settings": {
        "foreground": "#79c0ff"
      }
    },
    {
      "scope": "meta.separator",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#79c0ff"
      }
    },
    {
      "scope": "meta.output",
      "settings": {
        "foreground": "#79c0ff"
      }
    },
    {
      "scope": [
        "brackethighlighter.tag",
        "brackethighlighter.curly",
        "brackethighlighter.round",
        "brackethighlighter.square",
        "brackethighlighter.angle",
        "brackethighlighter.quote"
      ],
      "settings": {
        "foreground": "#8b949e"
      }
    },
    {
      "scope": "brackethighlighter.unmatched",
      "settings": {
        "foreground": "#ffa198"
      }
    },
    {
      "scope": [
        "constant.other.reference.link",
        "string.other.link"
      ],
      "settings": {
        "foreground": "#a5d6ff"
      }
    }
  ],
  "type": "dark"
});

export { githubDarkDefault as default };
